import axios from "axios";
// import { parseCookies } from "nookies";

export const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

// query key :
//  * teacherProfile ||
//  * student detail : `user_${id}`
export async function getUserDetail(id) {
  const { data } = await axios.get(`${URL}/user?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

/* MAIN DATA LIST QUERY */

// query key : teacherNotificationLoop
export async function getNotificationCount(id) {
  const { data } = await axios.get(
    `${URL}/notification/notificationcount?id=${id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  return data
}

// query key : teacherNotifications
export async function getNotificationList(id) {
  const { data } = await axios.get(
    `${URL}/notification/getnotifications?id=${id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  return data.reverse();
}

// query key : teacherInstrumentList
export async function getInstrumentList() {
  const { data } = await axios.get(`${URL}/instrument`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : `lesson_${id}`
export async function getLessonList(tch_id, page, pageSize, sort, filter, search) {
  const startDate = filter.startDate || new Date(2000, 0, 1)
  const endDate = filter.endDate || new Date()

  const queryObject = {
    page,
    pageSize,
    sort: sort ? "create_datetime" : "-create_datetime",
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    teacher_id: tch_id,
  }

  if (search !== "") {
    queryObject["search"] = search
  }

  const query = new URLSearchParams(queryObject);

  const { data } = await axios.get(`${URL}/lesson?${query}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}
