import axios from "axios";
// import { parseCookies } from "nookies";

export const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

// query key : adminProfile
export async function getAdminDetail(id) {
  const { data } = await axios.get(`${URL}/user?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

/* MAIN DATA LIST QUERY */

// query key : adminNotifications
export async function getNotificationList(
  id,
  page,
  pageSize,
  sort,
  type,
  unread = null
) {
  const queryObject = {
    page,
    pageSize,
    id,
    sort: sort ? "timestamp" : "-timestamp",
  };
  if (unread !== null) {
    queryObject["read"] = false;
  }
  if (type !== "") {
    queryObject["type"] = type;
  }
  const query = new URLSearchParams(queryObject);
  const { data } = await axios.get(
    page === undefined
      ? `${URL}/notification/getnotifications?id=${id}`
      : `${URL}/notification/getnotifications?${query}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  return data;
}

// query key : adminNotificationLoop
export async function getNotificationCount(id) {
  const { data } = await axios
    .get(`${URL}/notification/notificationcount?id=${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .catch((err) => {
      console.log("Error");
      console.log(err.response);
      return { data: 0 };
    });
  return data;
}

// query key :
//  * adminUserList => all user
//  * adminStudentList => type: Student
//  * adminTeacherList => type: Teacher
//  * adminAdminList => type: Admin
export async function getUserList(type) {
  if (type) {
    const { data } = await axios.get(`${URL}/user/?type=${type}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } else {
    const { data } = await axios.get(`${URL}/user`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  }
}

// query key : adminTempLessonList
export async function getTempLessonList(page, pageSize, sort, filter, search) {
  const startDate = filter.startDate || new Date(2000, 0, 1);
  const endDate = filter.endDate || new Date();

  const queryObject = {
    page,
    pageSize,
    sort: sort ? "create_datetime" : "-create_datetime",
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  if (search !== "") {
    queryObject["search"] = search;
  }

  const query = new URLSearchParams(queryObject);

  const { data } = await axios.get(`${URL}/lesson?${query}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminLessonList
export async function getLessonList() {
  const { data } = await axios.get(`${URL}/lesson`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminRecentLessonList
export async function getRecentLessonList(days) {
  const sevenDaysAgo = new Date().getTime() - days * 24 * 60 * 60 * 1000;

  const query = `?start_datetime={"$gt":"${new Date(
    sevenDaysAgo
  ).toISOString()}"}`;

  const { data } = await axios.get(`${URL}/lesson${query}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminLessons_${student_id}
export async function getLessonsByStudentId(std_id, page, pageSize, sort) {
  const query = new URLSearchParams({
    page,
    pageSize,
    sort: sort ? "start_datetime" : "-start_datetime",
    student_id: std_id,
  });
  const { data } = await axios.get(`${URL}/lesson?${query}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminLessons_${teacher_id}
export async function getLessonsByTeacherId(tch_id, page, pageSize, sort) {
  const query = new URLSearchParams({
    page,
    pageSize,
    sort: sort ? "start_datetime" : "-start_datetime",
    teacher_id: tch_id,
  });
  const { data } = await axios.get(`${URL}/lesson?${query}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminLessons_${package_id}
export async function getLessonsByPackageId(pkg_id) {
  const { data } = await axios.get(`${URL}/lesson/?package_id=${pkg_id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminInstrumentList
export async function getInstrumentList() {
  const { data } = await axios.get(`${URL}/instrument`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminPackageList
export async function getPackageList() {
  const { data } = await axios.get(`${URL}/package?sort=-start_date`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : adminStdPackages_${student_id}
export async function getPackagesByStudentId(std_id) {
  const { data } = await axios.get(`${URL}/package/?student_id=${std_id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

/* DATA DETAIL QUERY */

// query key : `user_${id}`
export async function getUserDetail(id) {
  const { data } = await axios.get(`${URL}/user/?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : `lesson_${id}`
export async function getLessonDetail(id) {
  const { data } = await axios.get(`${URL}/lesson/?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : `instrument_${id}`
export async function getInstrumentDetail(id) {
  const { data } = await axios.get(`${URL}/instrument/?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}

// query key : `package_${id}`
export async function getPackageDetail(id) {
  const { data } = await axios.get(`${URL}/package/?id=${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return data;
}
